<template>
    <div>
      <div v-if="editnote_dimensione_btn == 'block'">
        <b-button
          variant="outline-primary"
          class="mb-1" block
          @click="modificaNote(editnote_id,editnote_note)"
        >
          <feather-icon icon="Edit2Icon" /> Modifica Note
        </b-button>
      </div>
      <div v-else>
        <b-button
          variant="outline-primary"
          class="mb-1"
          @click="modificaNote(editnote_id,editnote_note)"
        >
          <feather-icon icon="Edit2Icon" /> Modifica Note
        </b-button>
      </div>

      <b-sidebar
        ref="sidebar_note"
        id="sidebar-note"
        bg-variant="white"
        title="Modifica Note"
        :visible="sidebar_visibile"
        right
        backdrop
        shadow
      >
        <div>
          <div class="py-0 px-2">
            <b-form>

              <quill-editor
                id="quil-content"
                v-model="campiformOpenEditNote.note"
                :options="editorOption"
                class="border-bottom-0"
                style="height: 250px;"
              />
              <div
                id="quill-toolbar"
                class="d-flex justify-content-end border-top-0"
              >
                <!-- Add a bold button -->
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
              </div>
              
              <div class="pt-1">
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="action_press_edit_note"
                  @click.prevent="salvaNote()"
                >
                  <div v-if="action_press_edit_note">Salvaggio in corso <b-spinner small class="ml-1" /></div>
                  <div v-else>Salva</div> 
                </b-button>
              </div>
            </b-form>

          </div>
        </div>
      </b-sidebar>

    </div>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BAlert, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BFormGroup, BForm, BFormInput, BButton, BSidebar, VBToggle, BBadge, BFormTextarea, BTable, BMediaBody, BMedia, BMediaAside, BAvatar, BOverlay } from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BAlert, 
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner,
    BFormGroup,
    BForm, 
    BFormInput, 
    BButton,
    BSidebar, 
    VBToggle, 
    BBadge,
    BFormTextarea, 
    BTable, 
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar, 
    BOverlay,

    quillEditor,

  },
  props: {
    sidebar_visibile: false,

    editnote_id: '',
    editnote_note: '',
    editnote_dimensione_btn: '',
    editnote_atc_aggiorna: '',
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: 'scrivi commento ...',
      },
      campiformOpenEditNote:{
        id_riga: '',
        note: '',
      },
      action_press_edit_note: false,
    }
  },
  methods: {
    modificaNote(id_riga, note){      
      this.campiformOpenEditNote.id_riga = id_riga;
      this.campiformOpenEditNote.note = note

      this.$root.$emit('bv::toggle::collapse', 'sidebar-note')

    },
    resetEditNotemodal(){
      this.campiformOpenEditNote = {
        id_riga: '',
        note: '',
      }
    },
    salvaNote(){
      this.$http.post('api/crm/customer/update_note', 
        this.campiformOpenEditNote
      ).then(response => { 

        //chiudi sidebar
        this.$refs.sidebar_note.hide();

        this.$swal({
          icon: 'success',
          title: response.data.reply.reply,
          //text: 'operazione effettuata correttamente',
          confirmButtonText: 'chiudi',
          customClass: {
          confirmButton: 'btn btn-success',
          },
        })

        if(this.editnote_atc_aggiorna == 'si'){
          //aggiorna note sulla pagina tramite un evento
          this.$emit('campoNoteCustomerAggiornato', response.data.reply.note);
        }

      }).catch(e => {
        console.log(e);
      });
    }
  }

}
</script>